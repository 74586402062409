import React, {useEffect} from "react";
import {Box, Grommet, Paragraph} from "grommet";
import {LOODS25} from "./Theme_Loods25"
import './loods-webshop.css';
import loods25Background from '../media/videos/loods-background.mp4';
import Typewriter from 'typewriter-effect';
import loodsLogo from '../media/images/loods25-logo.png';

export const LoodsWebshop = (props) => {

    useEffect(() =>{
                if (document.body.getElementsByClassName("ot-container").length === 0){
                    console.log("run shop scripts");
                    const eventixScript = document.createElement('script');
                    eventixScript.src = "https://shop.eventix.io/build/integrate.js"
                    eventixScript.async = false;
                    document.body.appendChild(eventixScript);
                }
                else {
                    console.log("Skip shop scripts")
                }
        });
   
return (
    <Grommet 
    className="grommet-box"
    theme={LOODS25}
    style={{}}>
        <video id='loods25-background' className='webshop-promo' autoPlay loop muted playsInline >
            <source src={loods25Background} type='video/mp4' />
        </video>
        <Box id="loods25-container"
        direction="row" 
        align="center"
        justify="center"
        flex="shrink">
            <Box
                pad="medium"
                align="center"
                animation= "slideDown"
                className="infobox">
                <img
                    id='loods25-logo'
                    fit='cover'
                    alt='Loods'
                    src={loodsLogo}
                    width="300px"
                />
                <Paragraph size="26px" textAlign='left' className="steps">
                    <br/>
                    Voordat de wereld weer groen kleurt willen we nog één keer met jullie raven. Hiervoor hebben wij een perfecte loods gevonden.<br/><br/>
                    Wanneer: <span style={{color:"#c1ff72"}}> zaterdag 15 maart</span> <br/><br/>
                    Inloop: <span style={{color:"#c1ff72"}}> van 17.00 uur - iedereen moet voor 20.00 uur binnen zijn! (Dit ivm de veiligheid) </span> <br/>
                    Dansen: <span style={{color:"#c1ff72"}}> tot 2.00 uur </span><br/><br/>
                    Waar:  <a id="loods25-location" href="https://maps.app.goo.gl/zE12d7EDf53EG43A6" style={{color:"#c1ff72"}}>
                        <Typewriter
                            options={{
                            strings: ["52.12878706673396 ,5.089658941986999"],
                            autoStart: true,
                            loop: false,
                            stop: true,
                            skipAddStyles: true ,
                            pauseFor: 100000000000000
                            }}
                        />
                    </a> <br/>
                    Entreeticket: <span style={{color:"#c1ff72"}}>€ 11,50 excl servicekosten </span><br/>
                    Deurverkoop:  <span style={{color:"#c1ff72"}}>€ 20,00 incl shotje </span> <br/>
                    <br/>
                    Drinken: <span style={{color:"#c1ff72"}}>Bring your own Booze - Geen glas! je snapt waarom </span><br/><br/>
                    Wij regelen: <br/>
                    <span style={{color:"#c1ff72"}}>Lampen, DJ's, Water, Bekers, Dixies, Toiletpapier, geen kluisjes!, Wat versnaperingen en veel liefde</span>
                </Paragraph>
                

            </Box>
            <Box
                align="center"
                className="shoppa">
                <div id="shop-frame" className="eventix-shop"
                    data-url="https://shop.eventix.io/db007949-8dd0-4402-9c67-cb511833e855"
                    style={{maxWidth: "600px"}}>
                </div>
            </Box>
            
            
        </Box>
        
    </Grommet>
    
);
}
export default LoodsWebshop;