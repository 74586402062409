import React, {useEffect } from "react";
import {Box, Text} from "grommet";
import LoadingIndicator from "../common/LoadingIndicator";
import './WK24_Webshop.css';
import webshop_promo from '../media/videos/WK24_WebshopPromo.mp4';

export const WK24_Webshop = (props) => {
    const [loading, setLoading] = React.useState(true);
    
    useEffect(() =>{
        if (document.body.getElementsByClassName("ot-container").length === 0){
            console.log("run shop scripts");
            const eventixScript = document.createElement('script');
            eventixScript.src = "https://shop.eventix.io/build/integrate.js"
            eventixScript.async = false;
            document.body.appendChild(eventixScript);
        }
        else {
            console.log("Skip shop scripts")
        }
        setLoading(false);
        var video = document.getElementById("webshop-promo");
        if (video !== null){
            video.setAttribute("autoPlay", "");
            video.setAttribute("playsinline", "");
            video.setAttribute("muted", "");
            video.play();
        }
        },[]);


return (
        <Box 
        id="top-box-webshop" 
        className="top-box-webshop"
        animation="fadeIn" 
        background={{color:"Black"}}
        align="center">
        {loading ? (
            <Box 
            pad="large"
            align="center"
            margin={{top:"20vh"}}>
                <LoadingIndicator/>
                <Text size="26px">Loading...</Text>
            </Box>
        ) : 
            <Box align="center" pad="none" animation="fadeIn" >
                
                <Box 
                id="shop-info"
                direction="row" 
                justify="center"
                flex="shrink"
                wrap>
                   <Box
                    align="center"
                    animation= "fadeIn"
                    className="infobox">    
                    <video id='webshop-promo' className='webshop-promo' autoPlay loop muted playsInline >
                        <source src={webshop_promo} type='video/mp4' />
                    </video>
                   </Box>
               </Box>
           </Box>
        }
        </Box>
);
}
export default WK24_Webshop;
